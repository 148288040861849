import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H3, P, Image } from "../AbstractElements";
import { Password } from "../utils/Constant";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "../ReduxToolkit/Hooks";
import { resetPasswordAction } from "../Api/auth";
import { dynamicImage } from "../Service";

const ResetPassword = () => {
  const [showPassWord, setShowPassWord] = useState(false);
  const [password, setPassword] = useState("");
  const { token } = useParams(); // Get the reset token from the URL parameters
  const navigate = useNavigate();
  const loggedIn = useAppSelector((state) => state.user.loggedIn);
  const [passwordMatched, setPasswordMatched] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const response = await resetPasswordAction(password, token);
      if (response.status === "success") {
        toast.success(response.message);
        navigate("/login");
      } else {
        if (response.response) toast.error(response.response.data.message);
        else toast.error("Reset password Error!");
      }
    } catch (error) {
      toast.error("Reset password failed!");
    }
  };

  useEffect(() => {
    if (password !== confirmPassword) setPasswordMatched(false);
    if (password === confirmPassword) setPasswordMatched(true);
  }, [password, confirmPassword]);

  return !loggedIn ? (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div>
              <div>
                <Link
                  className="logo text-center mb-3"
                  to={"https://judgmenttrade.com/"}
                >
                  <Image
                    src={dynamicImage("logo/logo.png")}
                    alt="LoginLogo"
                    width={300}
                  />
                </Link>
              </div>
              <div className="login-main">
                <Form className="theme-form" onSubmit={handleSubmit}>
                  <H3>{"Reset Password"}</H3>
                  <P>{"Enter a new password to reset your password"}</P>

                  <FormGroup>
                    <Label className="col-form-label">{Password}</Label>
                    <div className="form-input position-relative">
                      <Input
                        type={showPassWord ? "text" : "password"}
                        placeholder="*********"
                        required
                        autoComplete=""
                        onChange={(event) => setPassword(event.target.value)}
                      />
                      <div className="show-hide">
                        <span
                          onClick={() => setShowPassWord(!showPassWord)}
                          className={!showPassWord ? "show" : ""}
                        />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      {"Confirm Password"}
                    </Label>
                    <div className="form-input position-relative">
                      <Input
                        type={showPassWord ? "text" : "password"}
                        placeholder="*********"
                        required
                        autoComplete=""
                        onChange={(event) =>
                          setConfirmPassword(event.target.value)
                        }
                      />
                      <div className="show-hide">
                        <span
                          onClick={() => setShowPassWord(!showPassWord)}
                          className={!showPassWord ? "show" : ""}
                        />
                      </div>
                      {!passwordMatched ? (
                        <p className="text-danger mb-0">
                          Password does not confirmed
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-0 form-sub-title">
                    <div className="text-end mt-3">
                      <Btn
                        color="primary"
                        block
                        className="w-100"
                        type="submit"
                      >
                        {"Reset Password"}
                      </Btn>
                    </div>
                  </FormGroup>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  ) : (
    <Navigate to="/" />
  );
};

export default ResetPassword;
