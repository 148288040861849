import { Col, Container, Row } from "reactstrap";
import { P } from "../../AbstractElements";

const Footer = () => {
  return (
    <footer className="footer">
      <Container fluid>
        <Row>
          <Col
            md="12"
            className="footer-copyright d-flex flex-wrap align-items-center justify-content-between"
          >
            <P className="mb-0 f-w-600">Copyright 2024 © JudgmentTrade</P>
            <P className="mb-0 f-w-600">
              Contact -{" "}
              <a href="mailto:info@levinepstein.com">info@levinepstein.com</a>
            </P>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
