import { Card, CardBody } from "reactstrap";
import CommonCardHeader from "../../CommonElements/CommonCardHeader/CommonCardHeader";
import { Btn, H4, LI, UL } from "../../AbstractElements";
import {
  createCheckoutSession,
  updatePlanAction,
} from "../../Api/subscription";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { setUser } from "../../ReduxToolkit/Reducers/UserSlice";
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import "swiper/css/pagination";

import { Pagination } from "swiper/modules";

const plans = [
  { id: "free", name: "Free", price: "0", limit: 1 },
  {
    id: "price_1QEF9CRuFLmk2aChVgOr1KR5",
    name: "Standard",
    price: "59.95",
    limit: 175,
  },
  {
    id: "price_1QEF9oRuFLmk2aChfdqVn3Fe",
    name: "Business",
    price: "79.95",
    limit: 500,
  },
  {
    id: "price_1QEFAbRuFLmk2aChlVG4DMzE",
    name: "Premium",
    price: "169.95",
    limit: 1000,
  },
];

const BecomeMember = () => {
  const location = useLocation();
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const subscriptionStatus = params.get("subscription");
    if (subscriptionStatus === "successed") {
      toast.success("Subscription upgraded successfully!");
      const limit = params.get("limit");
      dispatch(setUser({ search_limit: Number(limit) }));
    } else if (subscriptionStatus === "cancelled") {
      toast.error("Subscription upgrade cancelled.");
    }
  }, [location.search]);

  const handleUpgrade = async (priceId: any, limit: any) => {
    try {
      const checkoutUrl = await createCheckoutSession(priceId, limit);
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error("Error during purchase:", error);
    }
  };
  const handleDownGrade = async (priceId: any, limit: any) => {
    try {
      const response = await updatePlanAction(priceId);
      console.log(response);
      if ((response.status = "success")) {
        dispatch(setUser({ search_limit: limit }));
        toast.success("Subscription downgrade successfully");
      }
      // Optionally, you can update the state to reflect the downgrade immediately
    } catch (error) {
      console.error("Error during downgrade:", error);
    }
  };
  return (
    <Card>
      <CommonCardHeader title={"Upgrade Plan"} />
      <CardBody className="pricing-block">
        <Swiper
          slidesPerView={4}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
          breakpoints={{
            300: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            720: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1080: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1440: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          }}
        >
          {plans.map((item, index) => (
            <SwiperSlide key={index} className="box-col-3">
              <div className="pricingtable">
                <div className="pricingtable-header">
                  <H4 className="title">{item.name}</H4>
                </div>
                <div className="price-value">
                  <span className="currency">$</span>
                  <span className="amount" style={{ fontSize: "30px" }}>
                    {item.price}
                  </span>
                  <span className="duration" style={{ marginLeft: 2 }}>
                    /mo
                  </span>
                </div>
                <div
                  style={{
                    height: "300px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <UL
                    className="pricing-content simple-list"
                    style={{ padding: 20 }}
                  >
                    {/* {item.tags>0? <LI>Up to {item.tags} tags to be used</LI>:<LI>No tags to be used</LI>} */}
                    {item.limit === 1 ? (
                      <LI>
                        {item.limit} active search and {item.limit}{" "}
                        jurisdiction
                      </LI>
                    ) : item.limit === 175 ? (
                      <>
                        <LI>Up to {item.limit} active searches</LI>
                        <LI>Full database, unlimited search</LI>
                      </>
                    ) : item.limit === 500 ? (
                      <>
                        <LI>Up to {item.limit} active searches</LI>
                        <LI>Full database, unlimited search</LI>
                      </>
                    ) : item.limit === 1000 ? (
                      <>
                        <LI>Up to {item.limit} active searches</LI>
                        <LI>Full database, unlimited search</LI>
                      </>
                    ) : (
                      <></>
                    )}

                    {user.search_limit === item.limit &&
                    user.search_limit !== 1 ? (
                      <LI>
                        Expiry Date:{" "}
                        {new Date(user.expire_date).toLocaleDateString()}
                      </LI>
                    ) : (
                      <></>
                    )}
                  </UL>
                  <div className="pricingtable-signup">
                    {user.search_limit === item.limit ? (
                      <Btn size="lg" color="secondary" disabled>
                        Current Plan
                      </Btn>
                    ) : (
                      <Btn
                        size="lg"
                        color="primary"
                        onClick={() => {
                          if (user.search_limit < item.limit)
                            handleUpgrade(item.id, item.limit);
                          else handleDownGrade(item.id, item.limit);
                        }}
                      >
                        {user.search_limit < item.limit
                          ? "Upgrade"
                          : "DownGrade"}
                      </Btn>
                    )}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </CardBody>
    </Card>
  );
};

export default BecomeMember;
