import { Container, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useEffect, useState } from "react";
import { Btn, H4, Image, P } from "../AbstractElements";
import {
  CreateAccount,
  CreateYourAccount,
  EmailAddress,
  EmailsPlaceHolder,
  FirstName,
  LastName,
  Password,
  SignIn,
  YourName,
} from "../utils/Constant";
import { Link, useNavigate } from "react-router-dom";
import { CommonFormPropsType } from "../Types/CommonElements/CommonElementsTypes";
import { useAppDispatch } from "../ReduxToolkit/Hooks";
import { registerAction } from "../Api/auth";
import { setUser } from "../ReduxToolkit/Reducers/UserSlice";
import { toast } from "react-toastify";
import { dynamicImage } from "../Service";

const Register = ({ alignLogo }: CommonFormPropsType) => {
  const [showPassWord, setShowPassWord] = useState(false);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordMatched, setPasswordMatched] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const registerHandle = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!passwordMatched) return;
    const _user = {
      firstname,
      lastname,
      email,
      password,
    };
    const { user, response } = await registerAction(_user);
    if (user !== undefined) {
      dispatch(setUser(user));
      navigate(`/?from=login`);
    } else {
      const _response: any = response;
      if (_response?.response) toast.error(_response.response.data.message);
      else toast.error("Register failed!");
    }
  };

  useEffect(() => {
    if (password !== confirmPassword) setPasswordMatched(false);
    if (password === confirmPassword) setPasswordMatched(true);
  }, [password, confirmPassword]);
  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div>
              <Link
                className="logo text-center mb-3"
                to={"https://judgmenttrade.com/"}
              >
                <Image
                  src={dynamicImage("logo/logo.png")}
                  alt="LoginLogo"
                  width={300}
                />
              </Link>
              <div className="login-main">
                <Form
                  className="theme-form"
                  onSubmit={(event) => registerHandle(event)}
                >
                  <H4>{CreateYourAccount}</H4>
                  <P>{"Enter your personal details to create account"}</P>
                  <FormGroup>
                    <Label className="col-form-label pt-0">{YourName}</Label>
                    <Row className="g-2">
                      <Col xs="6">
                        <Input
                          type="text"
                          required
                          placeholder={FirstName}
                          onChange={(event) => setFirstName(event.target.value)}
                        />
                      </Col>
                      <Col xs="6">
                        <Input
                          type="text"
                          required
                          placeholder={LastName}
                          onChange={(event) => setLastName(event.target.value)}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input
                      type="email"
                      required
                      placeholder={EmailsPlaceHolder}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{Password}</Label>
                    <div className="form-input position-relative">
                      <Input
                        type={showPassWord ? "text" : "password"}
                        placeholder="*********"
                        required
                        autoComplete=""
                        onChange={(event) => setPassword(event.target.value)}
                      />
                      <div className="show-hide">
                        <span
                          onClick={() => setShowPassWord(!showPassWord)}
                          className={!showPassWord ? "show" : ""}
                        />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      {"Confirm Password"}
                    </Label>
                    <div className="form-input position-relative">
                      <Input
                        type={showPassWord ? "text" : "password"}
                        placeholder="*********"
                        required
                        autoComplete=""
                        onChange={(event) =>
                          setConfirmPassword(event.target.value)
                        }
                      />
                      <div className="show-hide">
                        <span
                          onClick={() => setShowPassWord(!showPassWord)}
                          className={!showPassWord ? "show" : ""}
                        />
                      </div>
                      {!passwordMatched ? (
                        <p className="text-danger mb-0">
                          Passwords do not match
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </FormGroup>

                  <FormGroup className="mb-0">
                    <Btn block color="primary" className="w-100">
                      {CreateAccount}
                    </Btn>
                  </FormGroup>

                  <P className="mt-4 mb-0">
                    {"Already have an account?"}
                    <Link className="ms-2" to={`/login`}>
                      {SignIn}
                    </Link>
                  </P>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
