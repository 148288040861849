import { Container, Card, CardBody, Table, Spinner } from "reactstrap";
import CommonCardHeader from "../../../CommonElements/CommonCardHeader/CommonCardHeader";
import { useEffect, useState } from "react";
import { getUserListAction } from "../../../Api/auth";
import { toast } from "react-toastify";
import UsersTableBody from "./UsersTableBody";

const UsersTable = () => {
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getUsers = async () => {
    try {
      setLoading(true);
      const response = await getUserListAction();
      if (response.status === "success") {
        setUsers(response.payload);
      } else {
        if (response.response) toast.error(response.response.data.message);
        else toast.error("Get user list Error!");
      }
    } catch (error) {
      toast.error("Get user list failed!");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Container fluid>
      <Card>
        <CommonCardHeader headClass="card-no-border pb-1" title={"Users"} />
        <CardBody className="pt-0 projects px-2">
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spinner />
            </div>
          ) : (
            <div className="dataTables_wrapper">
              <div className="table-responsive theme-scrollbar">
                <Table
                  className="table display dataTable no-footer"
                  id="selling-product"
                >
                  <thead>
                    <tr>
                      <th>{"No"}</th>
                      <th>{"Name"}</th>
                      <th>{"Joined at"}</th>
                      <th>{"email"}</th>
                      <th>{"Tier"}</th>
                      <th>{"Search Limit"}</th>
                      <th>{"Search Count"}</th>
                    </tr>
                  </thead>
                  <UsersTableBody users={users} />
                </Table>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default UsersTable;
