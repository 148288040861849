import PricingContainer from "../../Component/Pricing/Pricing"

const Pricing = () => {
  return (
    <div className='page-body'>
      <PricingContainer />
    </div>
  )
}

export default Pricing