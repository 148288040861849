import { Image, P, LI } from "../../../../AbstractElements";
import { useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { dynamicImage } from "../../../../Service";
import ProfileBox from "./ProfileBox";
import { useState, useEffect, useRef } from "react";

const UserProfile = () => {
  const user = useAppSelector((state) => state.user);
  const [isProfileBoxVisible, setProfileBoxVisible] = useState(false);
  const profileRef = useRef<HTMLLIElement>(null);

  const toggleProfileBox = () => {
    setProfileBoxVisible(!isProfileBoxVisible);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
      setProfileBoxVisible(false);
    }
  };

  useEffect(() => {
    if (isProfileBoxVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isProfileBoxVisible]);

  return (
    <LI ref={profileRef} className="profile-nav px-0 py-0" style={{ cursor: 'pointer' }} onClick={toggleProfileBox}>
      <div className="d-flex profile-media align-items-center">
        <Image className="img-30" src={dynamicImage("dashboard/profile.png")} alt="user" />
        <div className="flex-grow-1">
          <span>{user.firstname + " " + user.lastname}</span>
          <P className="mb-0 font-outfit">
            <i className="fa fa-angle-down" />
          </P>
        </div>
      </div>
      {isProfileBoxVisible && <ProfileBox />}
    </LI>
  );
};

export default UserProfile;
