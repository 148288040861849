import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import LayoutRoutes from "./LayoutRoutes";
import { authRoutes } from "./AuthRoutes";
import Dashboard from "../Pages/Dashboard";
import Layout from "../Layout/Layout";

const RouterData = () => {
  return (
    <BrowserRouter basename={"/"}>
      <Routes>
        <Route path={"/"} element={<PrivateRoute />}>
          <Route path="/" element={<Layout />} >
            <Route index element={<Dashboard />} />
          </Route>
          <Route path={`/*`} element={<LayoutRoutes />} />
        </Route>
        {authRoutes.map(({ path, Component }, i) => (
          <Route path={path} element={Component} key={i} />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default RouterData;
