import { Card, CardBody, Col } from "reactstrap";
import { H1, H3 } from "../../../AbstractElements";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../ReduxToolkit/Hooks";

const UserInfo = () => {
  const user = useAppSelector((state) => state.user);

  return (
    <Col md={12} className="">
      <Card className="profile-greeting p-0">
        <CardBody>
          <Col xl={6}>
            <div className="img-overlay">
              <H1>
                {"Welcome to JudgmentTrade, " +
                  user.firstname +
                  " " +
                  user.lastname +
                  "!"}
              </H1>
              <H3 className="mt-4 mb-4">
                {
                  "Your Trusted Platform for Buying and Selling Court Judgments "
                }
              </H3>
              <Link className="btn btn-primary" to={`/pricing/upgrade-plan`}>
                {"Subscribe"}
              </Link>
            </div>
          </Col>
        </CardBody>
      </Card>
    </Col>
  );
};

export default UserInfo;
