export const Href = "#";

export const Back = "Back";
export const Pinned = "Pinned";
export const Cancel = "Cancel";
export const FirstName = "First name";
export const LastName = "Last name";
export const SignIn = "Sign In";
export const Password = "Password";
export const EmailAddress = "Email Address";
export const CopyText = "Copy text";
export const CreateYourAccount = "Create your account";
export const YourName = "Your Name";
export const CreateYourPassword = "Create Your Password";
export const SignInAccount = "Sign in to account";
export const RememberPassword = "Remember password";
export const ForgotPassword = "Forgot password?";
export const DoNotAccount = "Don't have account?";
export const CreateAccount = "Create Account";
export const EmailsPlaceHolder = "example@gmail.com";
export const Apply = "Apply";
export const QuickOption = "Quick option";
export const PreviewSettings = "PREVIEW SETTINGS";
export const Layout_Type = "Layout Type";
export const Sidebar_Icon = "Sidebar Icon";
export const UnlimitedColor = "Unlimited Color";
export const Mix_Layout = "MixLayout";
export const Sidebar_Type = "Sidebar Type";
export const Box = "Box";
export const Fill = "Fill";
export const LTRBadges = "LTR";
export const RTLBadges = "RTL";
export const Stroke = "Stroke";
export const Configuration = "Configuration";

export const AreaOfLaws = [
  "Banking",
  "Bankruptcy",
  "Commercial and Trade",
  "Constitutional and Civil Rights",
  "Corporate",
  "Energy",
  "Environmental and Natural Resource",
  "Intellectual Property",
  "Labor and Employment",
  "Personal Injury and Torts",
  "Personal and Family",
  "Probate",
  "Public and Administrative",
  "Real Property",
  "Securities and Financial Instruments",
  "Tax and Revenue",
  "Telecommunications",
];

export const CaseClasses = [
  "Civil",
  "Criminal",
  "Family",
  "Probate",
  "Traffic",
];
