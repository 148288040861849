import { Card, CardBody } from "reactstrap";
import CommonCardHeader from "../../CommonElements/CommonCardHeader/CommonCardHeader";
import "react-slideshow-image/dist/styles.css";

const Reviews = () => {
  return (
    <Card>
      <CardBody>
        <CommonCardHeader
          headClass="card-no-border pb-0 mb-3"
          title={
            "Discover Investment Opportunities with the Judgmenttrade Platform"
          }
        />
        <p>
          Our database enables you to find unsatisfied judgments that meet your
          specific criteria, no matter what you’re looking for. Once you’ve
          identified a suitable judgment, you can directly reach out to the
          judgment creditor’s attorney using the contact information listed.
          Alternatively, email us at
          <a
            href="mailto:info@levinepstein.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            {" "}
            info@levinepstein.com
          </a>
          , and we’ll assist with the purchase or any transaction details. With
          Judgmenttrade’s advanced search tools and proprietary database, you
          can efficiently locate and begin the process of acquiring unsatisfied
          judgments.
        </p>
      </CardBody>
    </Card>
  );
};

export default Reviews;
