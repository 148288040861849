import { createSlice } from "@reduxjs/toolkit";

interface initialStateInterface {
    videos: Array<any>,
    generating: boolean
}

const initialState: initialStateInterface = {
  videos: [],
  generating: false
};

const GenVideoSlice = createSlice({
  name: "VideoSlice",
  initialState,
  reducers: {
    setVideos: (state, action) => {
        state.videos = action.payload
    },
    setGenerating: (state, action) => {
        state.generating = action.payload
    }
  },
});
export const { setVideos, setGenerating } = GenVideoSlice.actions;

export default GenVideoSlice.reducer;