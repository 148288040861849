import axios from "axios";

export const getMasterDataAction = async () => {
  try {
    const response = await axios.get(`/unicourt/master_data`);
    if (response.data && response.data.status === "success") {
      return response.data;
    } else {
      console.error("Get Master Data failed with status:", response);
      return response;
    }
  } catch (error) {
    console.error("Error fetching Master Data", error);
  }
};

export const getCaseListAction = async (
  startDate: string,
  endDate: string,
  courtName: string,
  areaOfLaw: string,
  caseClass: string,
  startAmount: number,
  endAmount: number
) => {
  try {
    const response = await axios.get(`/unicourt/fetch_cases`, {
      params: {
        startDate: startDate,
        endDate: endDate,
        courtName: courtName,
        areaOfLaw: areaOfLaw,
        caseClass: caseClass,
        startAmount: startAmount,
        endAmount: endAmount,
      },
    });
    if (response.data && response.data.status === "success") {
      return response.data;
    } else {
      console.error("Get Case List failed with status:", response);
      return response;
    }
  } catch (error) {
    console.error("Error fetching case list:", error);
  }
};

export const getCaseDocketById = async (caseId: string) => {
  try {
    const response = await axios.get(`/unicourt/fetch_case_docket/${caseId}`);
    if (response.data && response.data.status === "success") {
      return response.data;
    } else {
      console.error("Get Case List failed with status:", response);
      return response;
    }
  } catch (error) {
    console.error("Error fetching case list:", error);
  }
};

export const getCaseDocumentById = async (caseId: string) => {
  try {
    const response = await axios.get(`/unicourt/fetch_case_document/${caseId}`);
    if (response.data && response.data.status === "success") {
      return response.data;
    } else {
      console.error("Get Case List failed with status:", response);
      return response;
    }
  } catch (error) {
    console.error("Error fetching case list:", error);
  }
};

export const getCaseDownloadDocument = async (caseDocumentId: string) => {
  try {
    const response = await axios.get(
      `/unicourt/fetch_case_download_document/${caseDocumentId}`
    );
    if (response.data && response.data.status === "success") {
      return response.data;
    } else {
      console.error("Get Case List failed with status:", response);
      return response;
    }
  } catch (error) {
    console.error("Error fetching case list:", error);
  }
};

export const filterByAmount = async (
  page: number,
  amountOption: any,
  startAmount: any,
  endAmount: any
) => {
  try {
    const response = await axios.get(
      `/unicourt/fetch_case_by_judgment_amount`,
      {
        params: {
          page: page,
          amountOption: amountOption,
          startAmount: startAmount,
          endAmount: endAmount,
        },
      }
    );
    if (response.data && response.data.status === "success") {
      return response.data;
    } else {
      console.error("Get Case List failed with status:", response);
      return response;
    }
  } catch (error) {
    console.error("Error fetching case list:", error);
  }
};
