// Importing necessary libraries and components
import axios, { AxiosHeaders } from "axios";
import { logoutAction } from "../Api/auth";

// Function to parse JWT tokens
export const parseJwt = (token: string | null) => {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

// Function to set the authentication token in localStorage and axios headers
export const setToken = (token: string) => {
  localStorage.setItem("token", token);
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

// Function to remove the authentication token from localStorage and axios headers
export const removeToken = () => {
  localStorage.removeItem("token");
  axios.defaults.headers.common["Authorization"] = "";
};

// Setting up default axios configuration for API requests
export const setAxiosConfig = (_axios: any, Store: any) => {
  _axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL + "/api";
  _axios.defaults.headers.post["Content-Type"] = "application/json";
  // Handling axios responses and errors globally
  _axios.interceptors.request.use((config: any) => {
    // Changed 'requests' to 'request'
    try {
      const token = localStorage.getItem("token");
      if (token) {
        const mHeaders = AxiosHeaders.from({
          Authorization: `Bearer ${token}`,
        });

        if (mHeaders) {
          config.headers = mHeaders;
        }
      }
    } catch (error) {}

    return config;
  });
  _axios.interceptors.response.use(
    function (successRes: any) {
      const data = successRes.data;
      return successRes;
    },
    function (error: any) {
      try {
        if (error && (error.data || (error.response && error.response.data))) {
          const data = error.data || error.response.data;
          // Parsing and handling error data, potential dispatch to redux store commented out
          Object.entries(data).forEach((v) => {
            const errors = v[1] || v;
            // Error notification logic is commented out
          });
          // If the response status is 401 (Unauthorized), perform logout
          if (error.response.status === 401) {
            logoutAction(Store.dispatch);
          }
        }
      } catch (err) {
        // Error handling could be implemented here
      }

      return error;
    }
  );
};
