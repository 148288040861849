import { Container, Row } from "reactstrap";
import UserInfo from "./UserInfo/UserInfo";
import SubscribeAlert from "../Pricing/Alert";
import CaseTable from "./CaseTable/CaseTable";

const DashboardContainer = () => {
  return (
    <Container fluid className="default-dashboard dashboard-2">
      <Row className="widget-grid">
        <SubscribeAlert />
        <UserInfo />
        <CaseTable />
      </Row>
    </Container>
  );
};

export default DashboardContainer;
