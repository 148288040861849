import { Route, Routes } from "react-router-dom";
import Layout from "../Layout/Layout";
import routes from "./Route";
import { useAppSelector } from "../ReduxToolkit/Hooks";

const LayoutRoutes = () => {
  const { role } = useAppSelector((state) => state.user);

  return (
    <Routes>
      {routes
        .filter((route: any) => {
          if (role === "ADMIN") {
            return true;
          } else {
            return route.auth !== "ADMIN";
          }
        })
        .map(({ path, Component }, i) => (
          <Route element={<Layout />} key={i}>
            <Route path={path} element={Component} />
          </Route>
        ))}
    </Routes>
  );
};

export default LayoutRoutes;
