import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { Image, SVG } from "../../AbstractElements";
import {
  handleResponsiveToggle,
  setToggleSidebar,
} from "../../ReduxToolkit/Reducers/LayoutSlice";
import { dynamicImage } from "../../Service";

const LogoWrapper = () => {
  const dispatch = useAppDispatch();
  const { toggleSidebar } = useAppSelector((state) => state.layout);
  const { sidebarIconType } = useAppSelector((state) => state.themeCustomizer);

  return (
    <>
      <div className="logo-wrapper">
        <a className="d-flex align-items-center pt-2 pb-1" href={`https://judgmenttrade.com`}>
          <Image
            src={dynamicImage("logo/logo.png")}
            alt="LoginLogo"
            width={160}
          />
        </a>
        <div
          className="back-btn"
          onClick={() => dispatch(handleResponsiveToggle())}
        >
          <i className="fa fa-angle-left"></i>
        </div>
        <div className="toggle-sidebar">
          <SVG
            className={`${sidebarIconType}-icon sidebar-toggle status_toggle middle`}
            iconId={`${sidebarIconType === "fill" ? "fill-" : ""}toggle-icon`}
            onClick={() => dispatch(setToggleSidebar(!toggleSidebar))}
          />
        </div>
      </div>
    </>
  );
};

export default LogoWrapper;
