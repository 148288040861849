import UsersTable from "../../Component/Dashboard/UsersTable/UsersTable";

const UserList = () => {
  return (
    <div className="page-body">
      <UsersTable />
    </div>
  );
};

export default UserList;
