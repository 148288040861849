import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Loader = () => {
  
  return (
    <>
      <div className="loader-wrapper " style={{ left: 0, background: "rgba(0,0,0,0.1)" }}>
        <div className="loader loader-1">
          <div className="loader-outter" />
          <div className="loader-inner" />
          <div className="loader-inner-1" />
        </div>
      </div>
    </>
  );
};

export default Loader;
