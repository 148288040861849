import React from "react";
import { H3, H5, H6 } from "../../../AbstractElements";
import { Modal, ModalHeader, ModalBody, Spinner, Table } from "reactstrap";
import { getCaseDocketById } from "../../../Api/unicourt";

interface JudgeTableBodyProps {
  cases: any;
  caseClass: string;
  areaOfLaw: string;
}

const JudgeTableBody: React.FC<JudgeTableBodyProps> = ({
  cases,
  caseClass,
  areaOfLaw,
}) => {
  const [caseDetail, setCaseDetail] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const [modal, setModal] = React.useState(false);

  const GetCaseDetail = async (caseId: string) => {
    try {
      setModal(true);
      setCaseDetail(null);
      setLoading(true);
      const response: any = await getCaseDocketById(caseId);
      if (response?.status === "success") {
        setCaseDetail(response.payload.case);
      } else {
        console.error(
          "Error fetching cases:",
          response.response?.data.message || "Unknown error"
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div style={{ padding: "5px 20px" }}>
        <Table responsive>
          <thead>
            <tr style={{ textWrap: "nowrap" }}>
              <th>No</th>
              <th>Case Name</th>
              <th>Court Name</th>
              <th>Area of Law</th>
              <th>Case Class</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {cases.map((c: any, i: any) => (
              <tr
                key={i}
                className="case-row"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  GetCaseDetail(c.caseId);
                }}
              >
                <td>{i + 1}</td>
                <td>
                  <H6>{c.caseName}</H6>
                </td>
                <td className="project-dot">
                  <H6>{c.court}</H6>
                </td>
                <td>{areaOfLaw}</td>
                <td>{caseClass}</td>
                <td>
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(c.amount)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal
        isOpen={modal}
        toggle={() => setModal(false)}
        style={{ width: "100%", maxWidth: 800 }}
      >
        <ModalHeader toggle={() => setModal(false)}>
          <H3>Case Detail</H3>
        </ModalHeader>
        <ModalBody>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spinner />
            </div>
          ) : (
            <>
              {caseDetail ? (
                <Table>
                  <tbody>
                    <tr>
                      <td style={{ width: 140, textAlign: "right" }}>
                        <H5>
                          <strong>Case Number:</strong>
                        </H5>
                      </td>
                      <td>
                        <H5>{caseDetail.caseNumber}</H5>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 140, textAlign: "right" }}>
                        <H5>
                          <strong>Case Name:</strong>
                        </H5>
                      </td>
                      <td>
                        <H5>{caseDetail.caseName}</H5>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 140, textAlign: "right" }}>
                        <H5>
                          <strong>Court Name:</strong>
                        </H5>
                      </td>
                      <td>
                        <H5>{caseDetail.courtName}</H5>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 140, textAlign: "right" }}>
                        <H5>
                          <strong>Plaintiffs:</strong>
                        </H5>
                      </td>
                      <td>
                        {caseDetail.plaintiffs.map(
                          (plaintiff: string, index: number) => (
                            <H5 key={index}>{plaintiff}</H5>
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 140, textAlign: "right" }}>
                        <H5>
                          <strong>Defendants:</strong>
                        </H5>
                      </td>
                      <td>
                        {caseDetail.defendants.map(
                          (defendant: string, index: number) => (
                            <H5 key={index}>{defendant}</H5>
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 140, textAlign: "right" }}>
                        <H5>
                          <strong>Attorneys:</strong>
                        </H5>
                      </td>
                      <td>
                        <Table>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Phone</th>
                              <th>Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {caseDetail.attorneys.map(
                              (attorney: any, index: number) => (
                                <tr key={index}>
                                  <td>
                                    <H5>{attorney.name}</H5>
                                  </td>
                                  <td>
                                    {attorney.phones.map(
                                      (phone: string, pIndex: number) => (
                                        <H5 key={pIndex}>{phone}</H5>
                                      )
                                    )}
                                  </td>
                                  <td>
                                    {attorney.emails.map(
                                      (email: string, eIndex: number) => (
                                        <H5 key={eIndex}>{email}</H5>
                                      )
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 140, textAlign: "right" }}>
                        <H5>
                          <strong>Case Class:</strong>
                        </H5>
                      </td>
                      <td>
                        <H5>{caseDetail.caseClass}</H5>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 140, textAlign: "right" }}>
                        <H5>
                          <strong>Area Of Law:</strong>
                        </H5>
                      </td>
                      <td>
                        <H5>{caseDetail.areaOfLaw}</H5>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 140, textAlign: "right" }}>
                        <H5>
                          <strong>Amount:</strong>
                        </H5>
                      </td>
                      <td>
                        <H5>
                          {caseDetail.amount
                            ? new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(caseDetail.amount)
                            : null}
                        </H5>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 10, textAlign: "right" }}>
                        <H5>
                          <strong>Entered Date:</strong>
                        </H5>
                      </td>
                      <td>
                        <H5>
                          {new Date(
                            caseDetail.enteredDate
                          ).toLocaleDateString()}
                        </H5>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 140, textAlign: "right" }}>
                        <H5>
                          <strong>Filed Date:</strong>
                        </H5>
                      </td>
                      <td>
                        <H5>
                          {new Date(caseDetail.filedDate).toLocaleDateString()}
                        </H5>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 140, textAlign: "right" }}>
                        <H5>
                          <strong>Documents:</strong>
                        </H5>
                      </td>
                      <td>
                        <H5>
                          {caseDetail.documents.map(
                            (document: string, index: number) => (
                              <div key={index}>
                                <a href={document} target="_blank">
                                  Case Document {index + 1}
                                </a>
                              </div>
                            )
                          )}
                        </H5>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              ) : null}
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default JudgeTableBody;
