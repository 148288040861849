import { MenuItem } from "../../Types/Layout/SidebarType";

export const UserMenuList: MenuItem[] = [
  {
    title: "General",
    lanClass: "lan-1",
    Items: [
      {
        title: "Dashboard",
        icon: "home",
        path: `/`,
        type: "link",
        lanClass: "lan-3",
      },

      {
        title: "Pricing",
        id: 4,
        icon: "ecommerce",
        type: "link",
        path: `/pricing/upgrade-plan`,
        active: false,
      },
    ],
  },
];

export const AdminMenuList: MenuItem[] = [
  {
    title: "General",
    lanClass: "lan-1",
    Items: [
      {
        title: "Dashboard",
        icon: "home",
        path: `/`,
        type: "link",
        lanClass: "lan-3",
      },
      {
        title: "Pricing",
        icon: "ecommerce",
        type: "link",
        path: `/pricing/upgrade-plan`,
        active: false,
      },
      {
        title: "Users",
        icon: "user",
        type: "link",
        path: `/users`,
        active: false,
      },
    ],
  },
];
