import { createSlice } from "@reduxjs/toolkit";
import { UserType } from "../../Types/Auth/UserType";
const initialState: UserType = {
  firstname: "",
  lastname: "",
  email: "",
  role: "USER",
  loggedIn: false,
  search_limit: 1,
  search_count: 0,
  expire_date: null,
};

const UserSlice = createSlice({
  name: "UserSlice",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.firstname = action.payload.firstname || state.firstname;
      state.lastname = action.payload.lastname || state.lastname;
      state.email = action.payload.email || state.email;
      state.loggedIn = true;
      state.role = action.payload.role || state.role;
      state.search_limit = action.payload.search_limit || state.search_limit;
      state.search_count = action.payload.search_count || state.search_count;
      state.expire_date = action.payload.expire_date || state.expire_date; // Update expire_date
    },
    removeUser: (state) => {
      state.firstname = "";
      state.lastname = "";
      state.email = "";
      state.role = "USER";
      state.loggedIn = false;
      state.search_limit = 1;
      state.search_count = 0;
      state.expire_date = null; // Reset expire_date
    },
  },
});

export const { setUser, removeUser } = UserSlice.actions;

export default UserSlice.reducer;
