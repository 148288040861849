import { Row, Card, CardBody, Col, Input } from "reactstrap";
import CommonCardHeader from "../../../CommonElements/CommonCardHeader/CommonCardHeader";
import { useState } from "react";
import CaseTableBody from "./CaseTableBody";
import { getCaseListAction } from "../../../Api/unicourt";
import { Btn, H5 } from "../../../AbstractElements";
import { toast } from "react-toastify";
import Loader from "../../../CommonElements/Loader/Loader";
import { useAppSelector } from "../../../ReduxToolkit/Hooks";
import { useDispatch } from "react-redux";
import { setUser } from "../../../ReduxToolkit/Reducers/UserSlice";
import { CaseClasses, AreaOfLaws } from "../../../utils/Constant";

const CaseTable = () => {
  const [cases, setCases] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<any>("");
  // const [endDate, setEndDate] = useState<any>("");
  const [caseClass, setCaseClass] = useState<any>(CaseClasses[0]);
  const [areaOfLaw, setAreaOfLaw] = useState<any>(AreaOfLaws[0]);
  const [courtName, setCourtName] = useState<any>("");
  const [startAmount, setStartAmount] = useState<any>("");
  const [endAmount, setEndAmount] = useState<any>("");
  const [loader, setLoader] = useState<boolean>(false);
  const user = useAppSelector((state) => state.user);
  const dispatch = useDispatch();

  const fetchCases = async () => {
    try {
      if (startDate === "") {
        toast.error("Please input the filed date!");
        return;
      }

      if (user.search_limit > user.search_count) {
        setCases([]);
        setLoader(true);

        const response = await getCaseListAction(
          startDate,
          // endDate,
          "",
          courtName,
          areaOfLaw,
          caseClass,
          startAmount,
          endAmount
        );

        if (response?.status === "success") {
          dispatch(setUser({ search_count: response.payload.search_count }));
          setCases(response.payload.case_list);
        } else {
          console.error(
            "Error fetching cases:",
            response.response?.data.message || "Unknown error"
          );
        }
      }
    } catch (error) {
      console.error("Fetch cases failed:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <Col xl="12">
      <Card>
        <CommonCardHeader
          headClass="card-no-border pb-0"
          title={"Unsatisfied Judgments"}
        />
        <CardBody className="pt-0 projects px-0">
          <Row className="d-flex align-items-center m-t-30 mx-5">
            <Col
              sm="12"
              md="12"
              lg="2"
              className="mb-2 d-flex align-items-center"
            >
              <H5>Filed Date: </H5>
            </Col>
            <Col sm="12" md="12" lg="4" className="mb-2">
              <Input
                type="date"
                value={startDate}
                onChange={(event) => {
                  setStartDate(event.target.value);
                }}
              />
            </Col>
            {/* <Col sm="6" md="6" lg="4" className="mb-2">
              <Input
                type="date"
                value={endDate}
                onChange={(event) => {
                  setEndDate(event.target.value);
                }}
              />
            </Col> */}
            <Col
              sm="12"
              md="12"
              lg="2"
              className="mb-2 d-flex align-items-center"
            >
              <H5>Federal and State: </H5>
            </Col>
            <Col sm="12" md="12" lg="4" className="mb-2">
              <Input
                type="text"
                value={courtName}
                onChange={(event) => {
                  setCourtName(event.target.value);
                }}
              />
            </Col>
            <Col
              sm="12"
              md="12"
              lg="2"
              className="mb-2 d-flex align-items-center"
            >
              <H5>Area of Law: </H5>
            </Col>
            <Col sm="12" md="12" lg="4" className="mb-1">
              <select
                className="form-control"
                onChange={(event: any) => {
                  setAreaOfLaw(event.target.value);
                }}
              >
                {AreaOfLaws.map((areaItem: string, index: number) => (
                  <option value={areaItem} key={index}>
                    {areaItem}
                  </option>
                ))}
              </select>
            </Col>
            <Col
              sm="12"
              md="12"
              lg="2"
              className="mb-2 d-flex align-items-center"
            >
              <H5>Case Class: </H5>
            </Col>
            <Col sm="12" md="12" lg="4" className="mb-2">
              <select
                className="form-control"
                onChange={(event: any) => {
                  setCaseClass(event.target.value);
                }}
              >
                {CaseClasses.map((caseItem: string, index: number) => (
                  <option value={caseItem} key={index}>
                    {caseItem}
                  </option>
                ))}
              </select>
            </Col>
            <Col
              sm="12"
              md="12"
              lg="2"
              className="mb-2 d-flex align-items-center"
            >
              <H5>Amount: </H5>
            </Col>
            <Col sm="12" md="12" lg="2" className="mb-2">
              <Input
                type="number"
                value={startAmount}
                onChange={(event) => {
                  setStartAmount(event.target.value);
                }}
              />
            </Col>
            <Col sm="12" md="12" lg="2" className="mb-2">
              <Input
                type="number"
                value={endAmount}
                onChange={(event) => {
                  setEndAmount(event.target.value);
                }}
              />
            </Col>
            <Col sm="12" md="12" lg="6" className="mb-1">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Btn
                  color="primary"
                  onClick={() => {
                    fetchCases();
                  }}
                  size="lg"
                  style={{ width: "200px" }}
                  disabled={user.search_count >= user.search_limit}
                >
                  Search
                </Btn>
              </div>
            </Col>
          </Row>

          <div className="dataTables_wrapper">
            {loader && <Loader />}
            <div className="table-responsive theme-scrollbar">
              <CaseTableBody
                cases={cases}
                areaOfLaw={areaOfLaw}
                caseClass={caseClass}
              />
            </div>
            {/* <div className="pagination mt-4 me-5 d-flex justify-content-end">
              <Btn
                color="primary"
                onClick={() => handlePageChange("previous")}
                disabled={currentPage === 1}
                className="m-1"
              >
                Previous
              </Btn>
              <Btn
                color="primary"
                onClick={() => handlePageChange("next")}
                disabled={currentPage === totalPages}
                className="m-1"
              >
                Next
              </Btn>
            </div> */}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CaseTable;
