import { Link, Navigate } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H3, P, Image } from "../AbstractElements";
import { EmailAddress } from "../utils/Constant";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "../ReduxToolkit/Hooks";
import { forgetPasswordAction } from "../Api/auth";
import { dynamicImage } from "../Service";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const loggedIn = useAppSelector((state) => state.user.loggedIn);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const response = await forgetPasswordAction(email);
      if (response.status === "success") {
        toast.success("Password reset email sent! Check your inbox.");
      } else {
        if (response.response) toast.error(response.response.data.message);
        else toast.error("Error!");
      }
    } catch (error) {
      toast.error("Error!");
    }
  };

  return !loggedIn ? (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div>
              <div>
                <Link
                  className="logo text-center mb-3"
                  to={"https://judgmenttrade.com/"}
                >
                  <Image
                    src={dynamicImage("logo/logo.png")}
                    alt="LoginLogo"
                    width={300}
                  />
                </Link>
              </div>
              <div className="login-main">
                <Form className="theme-form" onSubmit={handleSubmit}>
                  <H3>{"Reset your password"}</H3>
                  <P>{"Enter your email to reset your password"}</P>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input
                      type="email"
                      required
                      placeholder="john@smith.com"
                      value={email}
                      name="email"
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0 form-sub-title">
                    <div className="text-end mt-3">
                      <Btn
                        color="primary"
                        block
                        className="w-100"
                        type="submit"
                      >
                        {"Send"}
                      </Btn>
                    </div>
                  </FormGroup>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  ) : (
    <Navigate to="/" />
  );
};

export default ForgetPassword;
