import { H6 } from "../../../AbstractElements";
import { Link } from "react-router-dom";
import { Href } from "../../../utils/Constant";

interface UsersTableBodyProps {
  users: any;
}

const UsersTableBody: React.FC<UsersTableBodyProps> = ({ users = [] }) => {
  return (
    <tbody>
      {users.map((user: any, i: any) => (
        <tr key={i}>
          <td>{i + 1}</td>
          <td>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 ms-2">
                <Link to={Href}>
                  <H6>
                    {user.firstname} {user.lastname}
                  </H6>
                </Link>
              </div>
            </div>
          </td>
          <td> {new Date(user.created_at).toLocaleDateString()}</td>
          <td> {user.email}</td>
          <td>
            {" "}
            {user.search_limit === 1
              ? "Free"
              : user.search_limit === 175
              ? "Standard"
              : user.search_limit === 500
              ? "Business"
              : "Premium"}
          </td>
          <td>{user.search_limit}</td>
          <td>{user.search_count}</td>
        </tr>
      ))}
    </tbody>
  );
};

export default UsersTableBody;
