import { Col } from "reactstrap";
import { UL } from "../../../AbstractElements";
import UserProfile from "./UserProfile/UserProfile";

const RightHeader = () => {
  return (
    <Col
      xxl="8"
      xl="6"
      md="7"
      xs="8"
      className="nav-right pull-right right-header p-0 ms-auto"
    >
      <UL className="nav-menus flex-row simple-list">
        <UserProfile />
        <UserProfile />
      </UL>
    </Col>
  );
};

export default RightHeader;
