import Dashboard from "../Pages/Dashboard";
import Pricing from "../Pages/Pricing/Pricing";
import UserList from "../Pages/User";

const routes = [
  { path: "", Component: <Dashboard /> },
  { path: "/users", Component: <UserList />, auth: "ADMIN" },
  { path: "/pricing/upgrade-plan", Component: <Pricing /> },
];

export default routes;
