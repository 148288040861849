import axios from "axios";
import { parseJwt, removeToken, setToken } from "../utils";
import { removeUser } from "../ReduxToolkit/Reducers/UserSlice";

//Function to register a new user
export const registerAction = async (payload: any) => {
  try {
    const response = await axios.post("/auth/signup", payload);
    if (response.data && response.data.status === "success") {
      const user = parseJwt(response.data.payload.token); // Assuming response structure
      setToken(response.data.payload.token);
      return { response, user }; // This now correctly returns the user object
    } else {
      console.error("SignUp failed with status:", response);
      return { response };
    }
  } catch (err) {
    console.error("Error during SignUp:", err);
    throw err; // Optionally re-throw to handle the error in the component
  }
};

//Function to login
export const loginAction = async (payload: any) => {
  try {
    const response = await axios.post("/auth/signin", payload);
    if (response.data && response.data.status === "success") {
      const user = parseJwt(response.data.payload.token); // Assuming response structure
      setToken(response.data.payload.token);
      return { response, user }; // This now correctly returns the user object
    } else {
      console.error("Login failed with status:", response);
      return { response };
    }
  } catch (err) {
    console.error("Error during login:", err);
    throw err; // Optionally re-throw to handle the error in the component
  }
};

export const forgetPasswordAction = async (email: any) => {
  try {
    const response = await axios.post("/auth/forgot_password", { email });
    if (response.data && response.data.status === "success") {
      return response.data; // This now correctly returns the user object
    } else {
      console.error("Forget password failed with status:", response);
      return response;
    }
  } catch (err) {
    console.error("Error during forget password:", err);
    throw err; // Optionally re-throw to handle the error in the component
  }
};

export const resetPasswordAction = async (password: any, token: any) => {
  try {
    const response = await axios.post("/auth/reset_password", {
      password,
      token,
    });
    if (response.data && response.data.status === "success") {
      return response.data; // This now correctly returns the user object
    } else {
      console.error("Forget password failed with status:", response);
      return response;
    }
  } catch (err) {
    console.error("Error during forget password:", err);
    throw err; // Optionally re-throw to handle the error in the component    }
  }
};

export const getUserListAction = async () => {
  try {
    const response = await axios.get("/auth/users");
    if (response.data && response.data.status === "success") {
      return response.data; // This now correctly returns the user object
    } else {
      console.error("Forget password failed with status:", response);
      return response;
    }
  } catch (error) {
    console.error("Error fetching user list:", error);
  }
};

export const getOwnStatsAction = async () => {
  try {
    const response = await axios.get("/auth/user/stats");
    if (response.data && response.data.status === "success") {
      return response.data; // This now correctly returns the user object
    } else {
      console.error("Forget password failed with status:", response);
      return response;
    }
  } catch (error) {
    console.error("Error fetching user list:", error);
  }
};

//Function to logout

export const logoutAction = (dispatch: any) => {
  removeToken();
  dispatch(removeUser());
};
