import { configureStore } from "@reduxjs/toolkit";
import LayoutSlice from "./Reducers/LayoutSlice";
import ThemeCustomizerSlice from "./Reducers/ThemeCustomizerSlice";
import BookmarkHeaderSlice from "./Reducers/BookmarkHeaderSlice";
import UserSlice from "./Reducers/UserSlice";
import FilterSlice from "./Reducers/FilterSlice";
import GenVideoSlice from "./Reducers/GenVideoSlice";
import PersonalImageSlice from "./Reducers/PersonalImageSlice";

const Store = configureStore({
  reducer: {
    layout: LayoutSlice,
    themeCustomizer: ThemeCustomizerSlice,
    bookmarkHeader: BookmarkHeaderSlice,
    user: UserSlice,
    filterData: FilterSlice,
    video: GenVideoSlice,
    personalImages: PersonalImageSlice,
  },
});

export default Store;

export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;
