import { Provider } from "react-redux";
import RouterData from "./Routes";
import { ToastContainer } from "react-toastify";
import Store from "./ReduxToolkit/Store";
import { parseJwt, setAxiosConfig, setToken } from "./utils";
import axios from "axios";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./ReduxToolkit/Hooks";
import { setUser } from "./ReduxToolkit/Reducers/UserSlice";
import { getOwnStatsAction } from "./Api/auth";

setAxiosConfig(axios, Store);
const token = localStorage.getItem("token");

const App = () => {
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector((state) => state.user.loggedIn);

  const getUserStats = async () => {
    try {
      const response = await getOwnStatsAction();
      if (response.status === "success") {
        dispatch(
          setUser({
            search_limit: response.payload.search_limit,
            search_count: response.payload.search_count,
            expire_date: response.payload.expire_date,
          })
        );
      }
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    if (!token) return;
    setToken(token);
    const user = parseJwt(token);
    dispatch(setUser(user));
  }, [dispatch]);

  useEffect(() => {
    if (!loggedIn) return;
    getUserStats();
  }, [loggedIn]);

  return (
    <>
      <Provider store={Store}>
        <RouterData />
        <ToastContainer />
      </Provider>
    </>
  );
};

export default App;
